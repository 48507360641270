// extracted by mini-css-extract-plugin
export var bgGrey100 = "Card-module--bg-grey-100--926e3";
export var bgGrey150 = "Card-module--bg-grey-150--f6371";
export var bgGrey200 = "Card-module--bg-grey-200--6d00f";
export var bgGrey300 = "Card-module--bg-grey-300--f3344";
export var bgGrey400 = "Card-module--bg-grey-400--cb0f1";
export var bgGrey500 = "Card-module--bg-grey-500--5b14c";
export var bgGrey600 = "Card-module--bg-grey-600--45d0b";
export var bgGrey700 = "Card-module--bg-grey-700--3fd7d";
export var bgGrey800 = "Card-module--bg-grey-800--9b2fc";
export var bgGrey900 = "Card-module--bg-grey-900--9fc9a";
export var branchNameCls = "Card-module--branchNameCls--11a7b";
export var cardCls = "Card-module--cardCls--e5c6f";
export var hoursLinkCls = "Card-module--hoursLinkCls--2c072";
export var imageAreaCls = "Card-module--imageAreaCls--e3e5d";
export var textAreaCls = "Card-module--textAreaCls--84a26";
export var textGrey100 = "Card-module--text-grey-100--8bec9";
export var textGrey150 = "Card-module--text-grey-150--732b4";
export var textGrey200 = "Card-module--text-grey-200--2c746";
export var textGrey300 = "Card-module--text-grey-300--d715c";
export var textGrey400 = "Card-module--text-grey-400--03ba6";
export var textGrey500 = "Card-module--text-grey-500--91ed5";
export var textGrey600 = "Card-module--text-grey-600--0a12a";
export var textGrey700 = "Card-module--text-grey-700--7db32";
export var textGrey800 = "Card-module--text-grey-800--e9e65";
export var textGrey900 = "Card-module--text-grey-900--2d3d4";