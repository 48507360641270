import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Card as BootstrapCard } from "react-bootstrap"

import StoreLocatorModal from "./StoreLocatorModal"
import { ModalPortal, ToggleModal } from "../../../portals/Modal.portal"

import {
  cardCls,
  imageAreaCls,
  branchNameCls,
  textAreaCls,
  hoursLinkCls,
} from "./Card.module.scss"

const Card = ({ branch }) => {
  const {
    image,
    branchName,
    street,
    addressLine2,
    city,
    postcode,
    phone,
  } = branch

  return (
    <BootstrapCard className={cardCls}>
      <div className={imageAreaCls}>
        {image && (
          <GatsbyImage
            className="w-100 h-100"
            image={getImage(image.localFile)}
            alt={image.altText}
          />
        )}
        <h3 className={branchNameCls}>{branchName}</h3>
      </div>
      <div className={textAreaCls}>
        <address>
          <p className="mb-0">
            {street},{addressLine2 && <> {addressLine2}, </>}
            {`${city}, ${postcode}`}
            <br />
            <span className="d-block pt-2">{phone}</span>
          </p>
        </address>

        <ToggleModal
          toggle={show => {
            return (
              <u className={`${hoursLinkCls} text-decoration-underlined`} onClick={show}>
                Opening Hours
              </u>
            )
          }}
          content={hide => {
            return (
              <ModalPortal hide={hide} size="xl" centered={true}>
                <StoreLocatorModal branch={branch} />
              </ModalPortal>
            )
          }}
        />
      </div>
    </BootstrapCard>
  )
}

export default Card
