import { graphql } from "gatsby"
import React from "react"

import BranchesVariant2Component from "./BranchesVariant2"

export const fragment = graphql`
  fragment BranchesVariant2Fragment on WpPage_Flexlayouts_FlexibleLayouts_BranchesVariant2 {
    sectionHeading
    text
    branches {
      image {
        id
        altText
        sourceUrl
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
              height: 355
              placeholder: NONE
            )
          }
        }
      }
      iframe
      branchName
      street
      addressLine2
      city
      postcode
      phone
      openingHours {
        dayOfTheWeek
        hours
      }
    }
    config {
      padding {
        top {
          topMobile
          topTablet
          topDesktop
        }
        bottom {
          bottomMobile
          bottomTablet
          bottomDesktop
        }
      }
    }
  }
`

export const ACFBranchesVariant2 = ({
  sectionHeading,
  text,
  branches,
  config
}) => (
  <BranchesVariant2Component
    sectionHeading={sectionHeading}
    text={text}
    branches={branches}
    config={config}
  />
)
