import React from "react"
import { Row, Col } from "react-bootstrap"
import { v4 as uuidv4 } from "uuid"

import { Section, TextBlock, Container, SectionHeading } from "../../UI/Common"

import Card from "./Card"

const BranchesVariant2 = ({ sectionHeading, text, branches, config }) => {
  const { padding } = config || {}

  return (
    <Section padding={padding}>
      <Container>
        <>
          <SectionHeading text={sectionHeading} />
          <TextBlock mode="muted" text={text} />
          <Row>
            {branches.map(branch => (
              <Col xs={12} md={6} key={uuidv4()}>
                <Card branch={branch} />
              </Col>
            ))}
          </Row>
        </>
      </Container>
    </Section>
  )
}

export default BranchesVariant2
