import React, { Fragment } from 'react';
import { Row,Col } from 'react-bootstrap';
import { TextBlock } from '../../UI/Common';

import * as style from './StoreLocatorModal.module.scss';

const StoreLocatorModal = ({ branch: { branchName, street, addressLine2, city, postcode, phone, openingHours, iframe  }}) => {

    return (
        <Fragment>
          <Row className="m-0 p-0 flex-nowrap flex-lg-row flex-column-reverse" >
            <Col sm={12} lg={8} xl={8} className="p-0">
            {iframe && <TextBlock text={iframe} mode="map" /> }
            </Col>
            <Col sm={12} lg={4} xl={4} className="py-5 pl-3">
              <address className={`d-flex flex-column justify-content-center border-bottom ${style.storeDetails}`}>
                <h2 className="text-uppercase mb-0 pb-3">{branchName}</h2>
                {street}<br />
                {addressLine2 && <> {addressLine2}<br /></> }
                {`${city}, ${postcode}`}<br />
                <span className="pt-3">{phone}</span>
              </address>
              <div className={style.openingHours}>
                <h3 className="pb-4 text-uppercase mb-0">Opening Hours</h3>
                <div>
                  { openingHours.map((day,i) =>
                  <h4 className={`d-flex justify-content-between text-uppercase mb-0 ${style.openingDay}`} key={i}>
                    {day['dayOfTheWeek']} {' '}
                    <span>{day['hours']}</span>
                  </h4>
                  )}
                </div>
              </div>
              </Col>
          </Row>
        </Fragment>
    );
}


export default StoreLocatorModal