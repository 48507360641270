// extracted by mini-css-extract-plugin
export var addressDetail = "StoreLocatorModal-module--addressDetail--51885";
export var bgGrey100 = "StoreLocatorModal-module--bg-grey-100--13317";
export var bgGrey150 = "StoreLocatorModal-module--bg-grey-150--14ba5";
export var bgGrey200 = "StoreLocatorModal-module--bg-grey-200--f09a5";
export var bgGrey300 = "StoreLocatorModal-module--bg-grey-300--35762";
export var bgGrey400 = "StoreLocatorModal-module--bg-grey-400--f3a67";
export var bgGrey500 = "StoreLocatorModal-module--bg-grey-500--be889";
export var bgGrey600 = "StoreLocatorModal-module--bg-grey-600--59b69";
export var bgGrey700 = "StoreLocatorModal-module--bg-grey-700--ea955";
export var bgGrey800 = "StoreLocatorModal-module--bg-grey-800--90bef";
export var bgGrey900 = "StoreLocatorModal-module--bg-grey-900--42e68";
export var openingDay = "StoreLocatorModal-module--openingDay--17df1";
export var openingHours = "StoreLocatorModal-module--openingHours--b0f59";
export var storeDetails = "StoreLocatorModal-module--storeDetails--8e2a1";
export var textGrey100 = "StoreLocatorModal-module--text-grey-100--a5a3d";
export var textGrey150 = "StoreLocatorModal-module--text-grey-150--b26d5";
export var textGrey200 = "StoreLocatorModal-module--text-grey-200--7b127";
export var textGrey300 = "StoreLocatorModal-module--text-grey-300--66b43";
export var textGrey400 = "StoreLocatorModal-module--text-grey-400--b4896";
export var textGrey500 = "StoreLocatorModal-module--text-grey-500--c2f97";
export var textGrey600 = "StoreLocatorModal-module--text-grey-600--1b679";
export var textGrey700 = "StoreLocatorModal-module--text-grey-700--386e3";
export var textGrey800 = "StoreLocatorModal-module--text-grey-800--38004";
export var textGrey900 = "StoreLocatorModal-module--text-grey-900--aecfa";